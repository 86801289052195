<template>
  <div class="kind-detail" >
    <el-breadcrumb separator="/" class="bread-crumb">
      <el-breadcrumb-item>
        <span @click="$router.push('/')">首页</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="$route.query.nid">
        新闻详情
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="$route.query.sid">
        会议展览
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="kind">
      <div class="news-details" v-if="info">
        <h4 class="detail-title">{{ info.title }}</h4>
        <h6 class="times">
          <span>来源 : {{ info.source }}</span> &emsp;
          <span v-if="$route.query.nid">日期 : {{ info.newsdate }}</span>
          <span v-if="$route.query.sid">日期 : {{ info.dates }}</span>
        </h6>
        <div v-html="info.infos" style="color: #333333;font-size: 16px;"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {},
    };
  },

  components: {},

  computed: {},
  mounted() {
    if (this.$route.query.nid) {
      this.getInfo();
    }
    if (this.$route.query.sid) {
      this.getMeeting();
    }
  },
  methods: {
    async getInfo() {
      let { data } = await this.axios.get(
        `/index/news_detail?id=${this.$route.query.nid}&tid=${this.$route.query.tid}`
      );
      this.info = data.info;
    },
    async getMeeting() {
      let { data } = await this.axios.get(
        `/index/meeting_detail?id=${this.$route.query.sid}`
      );
      this.info = data.list;
    },
  },
};
</script>
<style lang="scss" scoped>
.kind-detail {
  margin-bottom: 373px;
  .kind {
    max-width: 1200px;
    margin: 0 auto;
    .news-details {
      // margin-top: 30px;
      padding: 20px 40px;
      box-sizing: border-box;
      h4 {
        font-size: 18pt;
        text-align: center;
        margin: 0;
        margin-top: 10px;
        padding-bottom: 20px;
        border-bottom: 1px dotted #000;
      }
      .times {
        font-size: 14px;
        text-align: center;
        margin: 10px;
        font-weight: normal;
        color: rgb(12, 141, 227);
      }
    }
  }
}
</style>
